import React, { useEffect, useState } from "react";
import Navbar from "../components/Sidebar/Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { FaEdit, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import Modal from "react-modal";
import axiosInstance from "../api";

const date = "12";

const MenuDescription = () => {
  const [menus, setMenus] = useState([]);

  const navigate = useNavigate();
  const fetchDataValid = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      navigate("/login");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/auth/validateToken`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.isValid) {
        return;
      } else {
        navigate("/login");
      }
    } catch (error) {
      console.error("Error during token validation:", error);
      navigate("/login");
    }
  };

  useEffect(() => {
    fetchDataValid();
  }, []);
  const [isAddMenuModalOpen, setAddMenuModalOpen] = useState(false);

  const [errors, setErrors] = useState({});
  const [menuData, setMenuData] = useState({
    name: "",
    price: "",
    description: "",
    menuType: "No",
    dprice: "",
    allergies: "",
    baigan: "",
    category: "",
  });

  // const [menuData, setMenuData] = useState({
  //   name: "",
  //   price: "",
  //   description: "",
  //   menuType: "No",
  //   dprice: "",
  //   allergies: "",
  // });

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setMenuData((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMenuData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (parseFloat(menuData.price) <= 0) {
      toast.info("Veuillez saisir un prix valide supérieur à zéro.");
      return; // Do not proceed if the price is invalid
    }
    if (parseFloat(menuData.dprice) > parseFloat(menuData.price)) {
      toast.info("Le prix de la réduction ne peut pas dépasser le prix du menu.");
      return;
    }
    try {
      const response = await axiosInstance.post(
        `/api/menu/add-single`,
        menuData
      );
      fetchMenus();
      toast.success("Menu added successfully!");
      setAddMenuModalOpen(false);
      setMenuData({
        name: "",
        price: "",
        description: "",
        menuType: "",
        dprice: "",
        baigan: "",
        category: "",
      });
    } catch (error) {
      console.error("Error adding menu:", error);
      toast.error("Failed to add menu. Please try again.");
    }
  };

  // Validate and submit the form
  const validateAndSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};
    if (menuData.price < 0) {
      newErrors.price = "Price cannot be negative.";
    }
    if (menuData.dprice < 0) {
      newErrors.dprice = "Discount price cannot be negative.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setErrors({});
    handleSubmit(e);
  };

  const fetchMenus = async () => {
    try {
      const response = await axiosInstance.get(`/api/menu/get-menu`);
      if (response.data && response.data) {
        setMenus(response.data);
      }
    } catch (err) {
      console.error("Failed to fetch menu data:", err);
    }
  };
  useEffect(() => {
    fetchMenus();
  }, []);

  const handleDelete = async (data) => {
    try {
      await axiosInstance.delete(`/api/menu/delete/${data._id}`);
      toast.success(`${data.name} has been deleted successfully.`);
      fetchMenus();
    } catch (error) {
      console.error("Error deleting menu:", error);
      toast.error("Failed to delete the menu.");
    }
  };

  const [isEditMenuModalOpen, setEditMenuModalOpen] = useState(false);
  const [editmenuData, editsetMenuData] = useState({
    name: "",
    price: "",
    description: "",
    menuType: "",
    dprice: "",
    allergies: "",
    baigan: "",
    category: "",
  });
  const handleEditChange = (event) => {
    const { name, value } = event.target;

    // Ensure the value is a valid non-negative number
    if (name === "dprice" && value !== "") {
      const numericValue = Math.max(0, parseFloat(value)); // Ensuring non-negative
      editsetMenuData((prevData) => ({
        ...prevData,
        [name]: numericValue,
      }));
    } else {
      editsetMenuData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const [editValue, setEditValue] = useState({});
  const handleEdit = (item) => {
    console.log(item);
    setEditValue(item);
    editsetMenuData(item);
    setEditMenuModalOpen(true);
  };
  const handleEditSubmit = async () => {
    try {
      if (parseFloat(editmenuData.price) <= 0) {
        toast.info("Veuillez saisir un prix valide supérieur à zéro.");
        return;
      }
      if (parseFloat(editmenuData.dprice) > parseFloat(editmenuData.price)) {
        toast.info("Le prix de réduction ne peut pas dépasser le prix principal.");
        return;
      }
      const response = await axiosInstance.put(
        `/api/menu/edit/${editValue._id}`,
        editmenuData
      );
      toast.success("Plats mis à jour !");
      fetchMenus();
      setEditMenuModalOpen(false);
    } catch (error) {
      console.error("Error updating menu:", error);
      toast.error("Failed to update menu!");
    }
  };

  const [addPreviousMenuModalOpen, setAddPreviousMenuModalOpen] =
    useState(false);
  const [formData, setFormData] = useState({
    date: "",
    menuType: "new",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmitPrevMenu = async (e) => {
    e.preventDefault();
    try {
      const editmenuData = {
        date: formData.date,
        menuType: formData.menuType,
      };

      const response = await axiosInstance.put(
        `/api/menu/prev/${date}`,
        editmenuData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status == 202);
      {
        toast.info(response.data.message);
      }
      if (response.status == 200) {
        setAddPreviousMenuModalOpen(false);
        fetchMenus();
        toast.success("Ajouter avec succès");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Something went wrong");
    }
  };
  const [activeTab, setActiveTab] = useState("BreakFast");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [searchQuery, setSearchQuery] = useState("");
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setCurrentPage(1);
    setSearchQuery("");
  };
  const paginate = (data) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return data.slice(startIndex, startIndex + itemsPerPage);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const [category, setCategory] = useState([]);

  const fetchSuggestions = async () => {
    try {
      const response = await axiosInstance.get(`/api/category/get-category`);
      setCategory(response.data.data);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    } finally {
    }
  };
  useEffect(() => {
    fetchSuggestions();
  }, []);

  const renderTable = (type, data) => {
    // Combine all menu items into a single list and filter based on search query
    const filteredMenu = data.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Paginate the filtered data
    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentItems = filteredMenu.slice(
      startIndex,
      startIndex + itemsPerPage
    );

    return (
      <div className="mb-8">
        <h2 className="text-lg font-semibold mb-4">{type}</h2>
        <div className="overflow-x-auto relative">
          <table className="w-full text-sm text-left text-black-500 dark:text-black-400">
            <thead className="text-xs text-black-700 uppercase bg-gray-50 dark:bg-gray-200 dark:text-black-400">
              <tr>
                <th scope="col" className="py-3 px-6">
                  Nom
                </th>
                <th scope="col" className="py-3 px-6">
                  Prix
                </th>
                <th scope="col" className="py-3 px-6">
                  Réductiom
                </th>
                <th scope="col" className="py-3 px-6">
                  Categorie
                </th>
                <th scope="col" className="py-3 px-6">
                  Description
                </th>
                <th scope="col" className="py-3 px-6">
                  Home Made
                </th>
                <th scope="col" className="py-3 px-6">
                  Allergie
                </th>
                <th scope="col" className="py-3 px-6">
                  Vegan
                </th>
                <th scope="col" className="py-3 px-6">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems.length > 0 ? (
                currentItems.map((item) => (
                  <tr
                    key={item._id}
                    className="bg-white border-b dark:bg-white-800 dark:border-white-700"
                  >
                    <td className="py-4 px-6">{item.name}</td>
                    <td className="py-4 px-6">{item.price}</td>
                    <td className="py-4 px-6">{item.dprice}</td>
                    <td className="py-4 px-6">{item.category}</td>
                    <td className="py-4 px-6">{item.description}</td>
                    <td className="py-4 px-6">{item.menuType}</td>
                    <td className="py-4 px-6">{item.allergies}</td>
                    <td className="py-4 px-6">
                      {item.baigan ? item.baigan : ""}
                    </td>
                    <td className="py-4 px-6 flex gap-2">
                      <FaEdit
                        onClick={() => handleEdit(item)}
                        className="text-blue-600 cursor-pointer hover:text-blue-800"
                        title="Edit"
                        size={20}
                      />
                      <FaTrash
                        onClick={() => handleDelete(item)}
                        className="text-red-600 cursor-pointer hover:text-red-800"
                        title="Delete"
                        size={20}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="text-center py-4">
                    <span className="text-gray-500 text-lg font-semibold italic">
                      No data found
                    </span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* Pagination Controls */}
        <div className="flex justify-center mt-4">
          <button
            disabled={currentPage === 1}
            onClick={() => setCurrentPage((prev) => prev - 1)}
            className="px-3 py-1 border rounded-l-md bg-gray-200 hover:bg-gray-300 disabled:opacity-50"
          >
            Avant
          </button>
          <span className="px-4 py-1 border bg-gray-100">{currentPage}</span>
          <button
            disabled={currentPage * itemsPerPage >= filteredMenu.length}
            onClick={() => setCurrentPage((prev) => prev + 1)}
            className="px-3 py-1 border rounded-r-md bg-gray-200 hover:bg-gray-300 disabled:opacity-50"
          >
            Après
          </button>
        </div>
      </div>
    );
  };

  const [excel, setExcel] = useState(false);

  const download = () => {
    setExcel(true);
  };

  const downloadSampleFile = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER}/menu-download`,
        { responseType: "blob" }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "sample-menu-weekly.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
      toast.success("Download Successfully");
      setExcel(false);
    } catch (error) {
      console.error("Error downloading the sample file:", error);
      toast.error("Internal Server Error");
    }
  };

  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };
  const handleSubmitWeekLy = async () => {
    if (!selectedFile) {
      toast.info("Veuillez sélectionner un fichier avant de le soumettre.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/menu/week`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      toast.success("File uploaded successfully!");
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("Failed to upload file.");
    }

    setModalOpen(false);
  };
  return (
    <div>
      <Navbar />
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-gray-200 rounded-lg dark:border-gray-700 mt-14">
          {/* Align buttons in a single line */}

          {excel && (
            <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
              <div className="bg-white dark:bg-gray-200 p-6 rounded-lg w-96">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-lg font-semibold">
                    Upload PLats en Excel
                  </h2>
                  <button
                    onClick={() => setExcel(false)}
                    className="text-black hover:text-gray-800"
                  >
                    ✕
                  </button>
                </div>
                <p className="text-sm mb-4 text-black">
                Veuillez télécharger les détails du menu sous forme de fichier Excel. Veillez à ce que
                les champs soient correctement formatés :
                </p>
                <ul className="list-disc list-inside text-sm text-black dark:text-black">
                  <li>
                    <strong>Menu Type:</strong> Type de menu (Homemade
                    if,Yes.else No).
                  </li>
                  <li>
                    <strong>Name:</strong> Nom de l'élément de menu (required).
                  </li>
                  <li>
                    <strong>Description:</strong>Description de l'élément de menu
                    (obligatoire).
                  </li>
                  <li>
                    <strong>Allergies:</strong> Détails sur les allergies (facultatif).
                  </li>
                  <li>
                    <strong>Category:</strong> Catégorie de l'article de menu (par ex,
                      végétarien, non végétarien).
                  </li>
                  <li>
                    <strong>Prix:</strong> Prix de l'article de menu .
                  </li>
                  <li>
                    <strong>Réduction:</strong> Prix de réduction de l'article de menu
                    menu.
                  </li>
                </ul>
                <button
                  onClick={downloadSampleFile}
                  className="mt-4 w-full px-4 py-2 text-white bg-black rounded-md"
                >
                  Télécharger fichier exemple
                </button>
              </div>
            </div>
          )}

          <div className="w-full p-2 flex flex-row items-center  gap-2">
            <button
              className="px-4 py-2 bg-gray-800 text-white rounded hover:bg-gray-600"
              onClick={() => setAddMenuModalOpen(true)}
            >
              Ajouter des plats
            </button>

            <button
              className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              onClick={download} // Handler for downloading the sample file
            >
              Télécharger fichier en exemple
            </button>

            <button
              className="px-4 py-2 bg-gray-200 text-black rounded hover:bg-gray-400"
              onClick={() => setModalOpen(true)} // Handler for uploading the Excel file
            >
              Upload Excel
            </button>
          </div>

          <div className="mt-4 flex justify-center relative">
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Rechercher..."
              className="px-4 py-2 pl-12 border-4 border-gray-600 rounded-lg shadow-md w-full"
            />
            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="w-6 h-6 text-gray-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-4.35-4.35m1.1-5.65a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
          </div>

          <div className="mt-6">{renderTable("All Menus", menus)}</div>
        </div>
      </div>

      {isAddMenuModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-lg w-full max-w-sm sm:max-w-md md:max-w-sm lg:max-w-md xl:max-w-lg p-10 overflow-y-auto max-h-[70vh]">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-bold mb-4">Add Daily Menu</h2>
              {/* Close Icon */}
              <button
                onClick={() => setAddMenuModalOpen(false)}
                className="text-gray-500 hover:text-gray-800 transition-colors duration-200 focus:outline-none"
              >
                <i
                  className="fa fa-times text-xl"
                  style={{ color: "red", fontSize: "30px" }}
                ></i>
              </button>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label
                  htmlFor="category"
                  className="block text-sm font-medium text-gray-700"
                >
                  Category
                </label>
                <select
                  name="category"
                  id="category"
                  value={menuData.category}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-black rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-3"
                  required
                >
                  <option value="" disabled>
                  Sélectionner catégorie
                  </option>
                  {category.map((category, index) => (
                    <option key={index} value={category.name}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Menu Name */}
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Nom du plat
                </label>
                <input
                  type="text"
                  name="name"
                  value={menuData.name}
                  onChange={handleChange}
                  placeholder="Entrer plat nom"
                  className="mt-1 block w-full border border-black rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-3"
                  required
                />
              </div>

              {/* Menu Price */}
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Prix du plat
                </label>
                <input
                  type="number"
                  name="price"
                  placeholder="Entrer plat prix"
                  value={menuData.price}
                  onChange={(e) => {
                    const value =
                      e.target.value === ""
                        ? ""
                        : Math.max(0, parseFloat(e.target.value) || 0);
                    setMenuData((prev) => ({
                      ...prev,
                      price: value,
                    }));
                  }}
                  onBlur={() => {
                    setMenuData((prev) => ({
                      ...prev,
                      price: Math.max(0, parseFloat(prev.price) || 0),
                    }));
                  }}
                  className={`mt-1 block w-full border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-3 ${
                    parseFloat(menuData.price) <= 0
                      ? "border-red-500"
                      : "border-black"
                  }`}
                />
                {parseFloat(menuData.price) <= 0 && (
                  <p className="text-red-500 text-sm">
                    Le prix doit être une valeur positive supérieure à zéro.
                  </p>
                )}
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Plat réduction
                </label>
                <input
                  type="number"
                  name="dprice"
                  placeholder="Entrer réduction du plat"
                  value={menuData.dprice}
                  onChange={(e) => {
                    const value =
                      e.target.value === ""
                        ? ""
                        : Math.max(0, parseFloat(e.target.value) || 0);
                    setMenuData((prev) => ({
                      ...prev,
                      dprice: value,
                    }));
                  }}
                  onBlur={() => {
                    setMenuData((prev) => ({
                      ...prev,
                      dprice: Math.max(0, parseFloat(prev.dprice) || 0),
                    }));
                  }}
                  className={`mt-1 block w-full border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-3 ${
                    parseFloat(menuData.dprice) > parseFloat(menuData.price) ||
                    parseFloat(menuData.dprice) < 0
                      ? "border-red-500"
                      : "border-black"
                  }`}
                />
                {parseFloat(menuData.dprice) > parseFloat(menuData.price) && (
                  <p className="text-red-500 text-sm">
                    Le prix de la réduction ne peut pas dépasser le prix du menu.
                  </p>
                )}
                {parseFloat(menuData.dprice) < 0 && (
                  <p className="text-red-500 text-sm">
                  Le prix de réduction doit être égal ou supérieur à zéro.
                  </p>
                )}
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Allergie
                </label>
                <input
                  type="text"
                  name="allergies"
                  value={menuData.allergies}
                  onChange={handleChange}
                  placeholder="Entrer Allergie"
                  className="mt-1 block w-full border border-black rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-3"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Vegan
                </label>
                <input
                  type="checkbox"
                  name="baigan"
                  checked={menuData.baigan === "Yes"}
                  onChange={(e) =>
                    handleChange({
                      target: {
                        name: "baigan",
                        value: e.target.checked ? "Yes" : "No",
                      },
                    })
                  }
                  className="mt-1 h-5 w-5 border border-black rounded shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
                <span className="ml-2 text-sm text-gray-600">
                  Is this Vegan?
                </span>
              </div>

              {/* Description */}
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Description
                </label>
                <textarea
                  name="description"
                  value={menuData.description}
                  onChange={handleChange}
                  placeholder="Entrer description"
                  className="mt-1 block w-full border border-black rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-3"
                  required
                ></textarea>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Homemade
                </label>
                <div className="mt-1 flex items-center">
                  <input
                    type="checkbox"
                    name="menuType"
                    checked={menuData.menuType === "Yes"}
                    onChange={(e) =>
                      handleChange({
                        target: {
                          name: "menuType",
                          value: e.target.checked ? "Yes" : "No",
                        },
                      })
                    }
                    className="h-4 w-4 text-blue-600 border-black rounded focus:ring-blue-500"
                  />
                  <span className="ml-2 text-sm text-gray-600">
                    Is this homemade?
                  </span>
                </div>
              </div>

              {/* Buttons */}
              <div className="flex justify-end">
                <button
                  type="button"
                  className="bg-gray-500 text-white px-3 py-3 rounded-md mr-3 text-lg"
                  onClick={() => setAddMenuModalOpen(false)}
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-gray-800 text-white rounded hover:bg-gray-600"
                >
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {isEditMenuModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-lg w-full max-w-sm sm:max-w-md md:max-w-sm lg:max-w-md xl:max-w-lg p-10 overflow-y-auto max-h-[90vh]">
            <h2 className="text-2xl font-bold mb-6 text-center">Edit Menu</h2>
            <form>
              <div className="mb-6">
                <label className="block text-gray-700 text-lg mb-2">Name</label>
                <input
                  type="text"
                  name="name"
                  value={editmenuData.name}
                  onChange={handleEditChange}
                  className="w-full border border-gray-300 p-3 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                />
              </div>
              <div className="mb-6">
                <label className="block text-gray-700 text-lg mb-2">
                  Prix
                </label>
                <input
                  type="number"
                  name="price"
                  value={editmenuData.price}
                  onChange={(e) => {
                    const value =
                      e.target.value === ""
                        ? ""
                        : Math.max(0, parseFloat(e.target.value) || 0); // Allow empty values temporarily
                    editsetMenuData((prev) => ({
                      ...prev,
                      price: value,
                    }));
                  }}
                  onBlur={() => {
                    // Validate on blur to ensure no empty or invalid values
                    editsetMenuData((prev) => ({
                      ...prev,
                      price:
                        prev.price === ""
                          ? 0
                          : Math.max(0, parseFloat(prev.price) || 0),
                    }));
                  }}
                  min="0"
                  className={`w-full border p-3 rounded focus:outline-none focus:ring-2 ${
                    parseFloat(editmenuData.price) <= 0 &&
                    editmenuData.price !== ""
                      ? "border-red-500"
                      : "border-gray-300"
                  }`}
                />
                {parseFloat(editmenuData.price) <= 0 &&
                  editmenuData.price !== "" && (
                    <p className="text-red-500 text-sm mt-1">
                      Le prix doit être une valeur positive supérieure à zéro.
                    </p>
                  )}
              </div>

              <div className="mb-6">
                <label className="block text-gray-700 text-lg mb-2">
                  Réduction
                </label>
                <input
                  type="number"
                  name="dprice"
                  value={editmenuData.dprice}
                  onChange={(e) => {
                    const value =
                      e.target.value === ""
                        ? ""
                        : Math.max(0, parseFloat(e.target.value) || 0); // Allow empty values temporarily
                    editsetMenuData((prev) => ({
                      ...prev,
                      dprice: value,
                    }));
                  }}
                  onBlur={() => {
                    // Validate on blur to ensure no empty or invalid values
                    editsetMenuData((prev) => ({
                      ...prev,
                      dprice:
                        prev.dprice === ""
                          ? 0
                          : Math.min(
                              Math.max(0, parseFloat(prev.dprice) || 0),
                              parseFloat(prev.price) || 0
                            ),
                    }));
                  }}
                  min="0"
                  className={`w-full border p-3 rounded focus:outline-none focus:ring-2 ${
                    parseFloat(editmenuData.dprice) >
                      parseFloat(editmenuData.price) ||
                    editmenuData.dprice === ""
                      ? "border-red-500"
                      : "border-gray-300"
                  }`}
                />
                {parseFloat(editmenuData.dprice) >
                  parseFloat(editmenuData.price) && (
                  <p className="text-red-500 text-sm mt-1">
                    Le prix de remise ne peut pas dépasser le prix principal.
                  </p>
                )}
                {parseFloat(editmenuData.dprice) < 0 && (
                  <p className="text-red-500 text-sm mt-1">
                    Le prix de réduction doit être égal ou supérieur à zéro.
                  </p>
                )}
              </div>

              <div className="mb-6">
                <label className="block text-gray-700 text-lg mb-2">
                  Allergie
                </label>
                <input
                  type="text"
                  name="allergies"
                  value={editmenuData.allergies}
                  onChange={handleEditChange}
                  className="w-full border border-gray-300 p-3 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                />
              </div>
              <div className="mb-6">
                <label className="block text-gray-700 text-lg mb-2">
                  Vegan
                </label>
                <input
                  type="checkbox"
                  name="baigan"
                  checked={editmenuData.baigan === "Yes"}
                  onChange={(e) =>
                    handleEditChange({
                      target: {
                        name: "baigan",
                        value: e.target.checked ? "Yes" : "No",
                      },
                    })
                  }
                  className="h-5 w-5 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                />
                <span className="ml-2 text-sm text-gray-600">
                  C'est Vegan?
                </span>
              </div>

              <div className="mb-6">
                <label className="block text-gray-700 text-lg mb-2">
                  Description
                </label>
                <textarea
                  name="description"
                  value={editmenuData.description}
                  onChange={handleEditChange}
                  className="w-full border border-gray-300 p-3 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                ></textarea>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Homemade
                </label>
                <div className="mt-1 flex items-center">
                  <input
                    type="checkbox"
                    name="menuType"
                    checked={editmenuData.menuType === "Yes"}
                    onChange={(e) =>
                      handleEditChange({
                        target: {
                          name: "menuType",
                          value: e.target.checked ? "Yes" : "No",
                        },
                      })
                    }
                    className="h-4 w-4 text-blue-600 border-black rounded focus:ring-blue-500"
                  />
                  <span className="ml-2 text-sm text-gray-600">
                    C'est homemade?
                  </span>
                </div>
              </div>
              <div className="mb-6">
                <label className="block text-gray-700 text-lg mb-2">
                  Categories
                </label>
                <select
                  name="category"
                  value={editmenuData.category} // Bind value to editmenuData.category
                  onChange={(e) => {
                    // Ensure onChange properly updates the state
                    const selectedValue = e.target.value;
                    handleEditChange({
                      target: { name: "category", value: selectedValue },
                    });
                  }}
                  className="w-full border border-gray-300 p-3 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                >
                  <option value="" disabled>
                  Sélectionner catégorie
                  </option>
                  {category.map((cat, index) => (
                    <option key={index} value={cat.name}>
                      {cat.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => setEditMenuModalOpen(false)}
                  className="bg-gray-500 text-white px-6 py-3 rounded-md mr-3 text-lg"
                >
                  Annuler
                </button>
                <button
                  type="button"
                  onClick={handleEditSubmit}
                  className="px-4 py-2 bg-black text-white rounded-md mr-2"
                >
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {addPreviousMenuModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg w-1/2 max-w-lg">
            <h2 className="text-2xl font-bold mb-6">Add/Edit Previous Menu</h2>
            <form onSubmit={handleSubmitPrevMenu}>
              {/* Date Field */}
              <div className="mb-6">
                <label className="block text-gray-700 text-lg">Date</label>
                <input
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={handleInputChange}
                  className="w-full border p-3 rounded-md text-lg"
                />
              </div>

              {/* Menu Selection */}
              <div className="mb-6">
                <label className="block text-gray-700 text-lg">
                  Choix du plat
                </label>
                <select
                  name="menuType"
                  onChange={handleInputChange}
                  className="w-full border p-3 rounded-md text-lg"
                >
                  <option value="new">Ajouter le plat précédent</option>
                  <option value="exist">Ajouter avec un plat existant</option>
                </select>
              </div>

              {/* Buttons */}
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => setAddPreviousMenuModalOpen(false)}
                  className="bg-gray-500 text-white px-6 py-3 rounded-md mr-3 text-lg"
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-black text-white rounded-md mr-2"
                >
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setModalOpen(false)}
        className="bg-white p-6 rounded-lg shadow-lg max-w-lg mx-auto mt-20"
        overlayClassName="bg-black bg-opacity-50 fixed inset-0 flex justify-center items-center"
      >
        <h2 className="text-2xl font-bold mb-4">Upload hebdomadaire plats</h2>
        <input
          type="file"
          accept=".xlsx, .xls"
          onChange={handleFileChange}
          className="mb-4"
        />
        <button
          className="px-4 py-2 text-black rounded-md bg-[#B1D4E0]-100 dark:bg-[#B1D4E0] "
          onClick={handleSubmitWeekLy}
        >
          Envoyer
        </button>
        <button
          className="px-4 py-2 bg-black text-white rounded-md mr-2"
          onClick={() => setModalOpen(false)}
        >
          Annuler
        </button>
      </Modal>
    </div>
  );
};

export default MenuDescription;
