import React, { useState, useEffect } from "react";
import Navbar from "../components/Sidebar/Sidebar";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../components/Loader/Loader";
import axiosInstance from "../api";

const Menudetails = () => {
  const [calendarData, setCalendarData] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `/api/menu/get-menu`
      );
      setLoading(false);
      if (response.data.success) {
        setCalendarData(response.data.data);
      }
    } catch (error) {
      setLoading(false);

      console.log("Errr" + error);
    }
  };

  const fetchDataValid = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      navigate("/login");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/auth/validateToken`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.isValid) {
        return;
      } else {
        navigate("/login");
      }
    } catch (error) {
      console.error("Error during token validation:", error);
      navigate("/login");
    }
  };

  useEffect(() => {
    fetchDataValid();
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const handleDateClick = (date) => {
    navigate(`/menu-description/${date}`);
  };

  const sortedDates = Object.keys(calendarData).sort(
    (a, b) => new Date(a) - new Date(b)
  );

  return (
    <div>
      <Navbar />
      {loading && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <Loader />
        </div>
      )}
      <div className="p-4 sm:ml-64">
        <h1 className="text-2xl font-bold mb-4">Menu Details</h1>
        <div className="grid grid-cols-7 gap-4">
          {sortedDates.map((date) => {
            const isToday =
              new Date(date).toDateString() === new Date().toDateString();
            const isFuture = new Date(date) > new Date();
            return (
              <div
                key={date}
                className={`p-4 border rounded-lg cursor-pointer ${
                  calendarData[date].hasMenu ? "bg-green-200" : "bg-gray-100"
                }`}
                onClick={() => handleDateClick(date)}
              >
                <p className="text-center font-bold text-black">
                  {new Date(date).getDate()}
                </p>
                {isToday && (
                  <p className="text-sm text-center mt-2 text-blue-800">
                    📅 Today
                  </p>
                )}
                {isFuture && (
                  <p className="text-sm text-center mt-2 text-yellow-800">
                    ⏳ Upcoming
                  </p>
                )}
                {calendarData[date].hasMenu && (
                  <p className="text-sm text-center mt-2 text-green-800">
                    🍴 Menu Available
                  </p>
                )}
                {!calendarData[date].hasMenu && (
                  <p className="text-sm text-center mt-2 text-gray-600">
                    No Menu
                  </p>
                )}
              </div>
            );
          })}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mb-5 mt-5  ">
          <div
            className="flex flex-col justify-between items-center min-h-[200px] sm:min-h-[250px] p-6 rounded-lg bg-white text-black shadow-lg hover:shadow-xl transform hover:scale-105 transition-all duration-300 cursor-pointer"
            //  onClick={() => setAddMenuModalOpen(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
              className="w-16 h-16 text-black hover:text-blue-500 transition-colors duration-300"
            >
              <path d="M12 3l7 6V20a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-5H9v5a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9l7-6a1 1 0 0 1 1.32 0zM12 1.75L4.2 8.38a2 2 0 0 0-.7 1.52V20a3 3 0 0 0 3 3h4a3 3 0 0 0 3-3v-5h2v5a3 3 0 0 0 3 3h4a3 3 0 0 0 3-3V9.9a2 2 0 0 0-.7-1.52L12 1.75z" />
            </svg>

            <div className="flex justify-between items-center mt-4">
              <p className="text-black text-lg">
                <span className="font-semibold">Add Menu </span>Click here to
                add a new menu item.
              </p>
            </div>
          </div>

          <div
            className="flex flex-col justify-between items-center min-h-[200px] sm:min-h-[250px] p-6 rounded-lg bg-white text-black shadow-lg hover:shadow-xl transform hover:scale-105 transition-all duration-300 cursor-pointer"
            //  onClick={() => setModalOpen(true)}
          >
            <div className="flex justify-center items-center mb-4">
              {/* SVG Calendar Icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 24 24"
                className="w-12 h-12 sm:w-14 sm:h-14 lg:w-16 lg:h-16 text-black hover:text-red-500 transition-colors duration-300"
              >
                <path d="M19 4h-1V2h-2v2H8V2H6v2H5a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3zM5 6h1v2h2V6h8v2h2V6h1a1 1 0 0 1 1 1v2H4V7a1 1 0 0 1 1-1zm14 14H5a1 1 0 0 1-1-1V11h16v8a1 1 0 0 1-1 1z" />
              </svg>
            </div>
            <div className="flex justify-between items-center mt-4">
              <p className="text-black text-lg">
                <span className="font-semibold">Add Weekly Menu </span>
                Click here to add the weekly menu.
              </p>
            </div>
          </div>

          {/* Download Sample File Card */}
          <div
            className="flex flex-col justify-between items-center min-h-[200px] sm:min-h-[250px] p-6 rounded-lg bg-white text-black shadow-lg hover:shadow-xl transform hover:scale-105 transition-all duration-300 cursor-pointer"
            // onClick={downloadSampleFile}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
              className="w-16 h-16 text-black hover:text-green-500 transition-colors duration-300"
            >
              <path d="M19 9h-4V3h-6v6H5l7 7 7-7zM5 14h14v2H5z" />
            </svg>
            <div className="flex justify-between items-center mt-4">
              <p className="text-black text-lg">
                <span className="font-semibold">
                  Download Sample File (Weekly Menu)
                </span>{" "}
                Download a sample file here.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menudetails;
