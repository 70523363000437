import React, { useEffect, useState } from "react";
import { Images } from "../../assets";
import { useNavigate } from "react-router-dom";
import { FaArrowUp, FaHome } from "react-icons/fa";
import axiosInstance from "../../api";
const Header = () => {
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const [suggestions, setSuggestions] = useState([]);

  const fetchSuggestions = async () => {
    try {
      const response = await axiosInstance.get(`/api/category/get-category`);
      setSuggestions(response.data.data);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  useEffect(() => {
    fetchSuggestions();
  }, []);

  const styles = {
    curvedTextStyle: {
      fontWeight: "bold",
      fill: "white",
      fontFamily: "'Chunk Five', 'Roboto Slab', serif",
      textTransform: "uppercase",
      letterSpacing: "0px",
    },
  };

  return (
    <div>
      <nav className="bg-[#713D11] text-white p-4 flex items-center justify-between">
        <div className="logo-container flex justify-center items-center w-auto h-[152px]">
          <img
            className="logo w-12 sm:w-14 md:w-16 lg:w-18 xl:w-18 h-[152px] object-contain"
            src={Images.MAIN_LOGO}
            alt="Logo"
          />
        </div>

        <button
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          className="absolute top-4 right-4 text-white px-4 py-2 rounded-md flex items-center transition-all z-50"
        >
          {/* SVG Icon for Menu */}
          <svg
            className="w-8 h-8"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M3 12h18M3 6h18M3 18h18" />
          </svg>
        </button>
      </nav>

      <div className="relative w-full h-auto bg-black">
        {/* Sidebar */}
        <div
          className={`fixed top-0 right-0 h-full w-64 bg-[#f5e2cb] shadow-md transform ${
            isSidebarOpen ? "translate-x-0" : "translate-x-full"
          } transition-transform duration-300 z-50`}
        >
          {/* Close Button */}
          <button
            onClick={() => setIsSidebarOpen(false)}
            className="absolute top-4 right-4 bg-white text-black px-2 py-1 rounded-full shadow-md hover:bg-gray-200"
          >
            {/* SVG Icon for Close */}
            <svg
              className="w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>

          <div className="flex flex-col p-6 text-white">
            <button
              onClick={() => {
                navigate("/");
                setIsSidebarOpen(false);
              }}
              className="text-lg font-medium py-2 mt-8 px-4 text-black rounded-lg transition-all"
            >
              {/* SVG Icon for Home */}
              <svg
                className="w-5 h-5 text-black inline-block mr-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M3 12l2 2 6-6 6 6 2-2M5 17h14a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2z" />
              </svg>
              Home
            </button>
          </div>
        </div>

        {/* Back to Top Button */}
        {showScrollButton && (
          <button
            onClick={scrollToTop}
            className="fixed bottom-4 right-4 bg-[#5C4033] text-white p-4 rounded-full shadow-lg hover:bg-[#4E3629] transition-all z-50"
            aria-label="Scroll to Top"
          >
            <FaArrowUp className="text-2xl" />
          </button>
        )}
      </div>
    </div>
  );
};

export default Header;
