import React from 'react';
import './Footer.css';
import { Images } from '../../assets';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <img src={Images.BG_MAIN_LOGO} alt="Logo" />
        </div>
        <div className="social-icons">
          <a href="#" aria-label="Instagram">
            <svg width="80" height="80" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M23.075 5.91663H47.925C57.3917 5.91663 65.0833 13.6083 65.0833 23.075V47.925C65.0833 52.4756 63.2756 56.8399 60.0578 60.0577C56.84 63.2756 52.4757 65.0833 47.925 65.0833H23.075C13.6083 65.0833 5.91666 57.3916 5.91666 47.925V23.075C5.91666 18.5243 7.7244 14.16 10.9422 10.9422C14.16 7.72437 18.5243 5.91663 23.075 5.91663ZM22.4833 11.8333C19.6588 11.8333 16.9499 12.9553 14.9526 14.9526C12.9554 16.9499 11.8333 19.6587 11.8333 22.4833V48.5166C11.8333 54.4037 16.5962 59.1666 22.4833 59.1666H48.5167C51.3412 59.1666 54.0501 58.0446 56.0473 56.0473C58.0446 54.0501 59.1667 51.3412 59.1667 48.5166V22.4833C59.1667 16.5962 54.4037 11.8333 48.5167 11.8333H22.4833ZM51.0312 16.2708C52.012 16.2708 52.9526 16.6604 53.6461 17.3539C54.3396 18.0474 54.7292 18.988 54.7292 19.9687C54.7292 20.9495 54.3396 21.89 53.6461 22.5835C52.9526 23.277 52.012 23.6666 51.0312 23.6666C50.0505 23.6666 49.1099 23.277 48.4164 22.5835C47.7229 21.89 47.3333 20.9495 47.3333 19.9687C47.3333 18.988 47.7229 18.0474 48.4164 17.3539C49.1099 16.6604 50.0505 16.2708 51.0312 16.2708ZM35.5 20.7083C39.423 20.7083 43.1853 22.2667 45.9593 25.0407C48.7333 27.8146 50.2917 31.577 50.2917 35.5C50.2917 39.423 48.7333 43.1853 45.9593 45.9592C43.1853 48.7332 39.423 50.2916 35.5 50.2916C31.577 50.2916 27.8147 48.7332 25.0407 45.9592C22.2667 43.1853 20.7083 39.423 20.7083 35.5C20.7083 31.577 22.2667 27.8146 25.0407 25.0407C27.8147 22.2667 31.577 20.7083 35.5 20.7083ZM35.5 26.625C33.1462 26.625 30.8888 27.56 29.2244 29.2244C27.56 30.8888 26.625 33.1462 26.625 35.5C26.625 37.8538 27.56 40.1111 29.2244 41.7755C30.8888 43.4399 33.1462 44.375 35.5 44.375C37.8538 44.375 40.1112 43.4399 41.7756 41.7755C43.44 40.1111 44.375 37.8538 44.375 35.5C44.375 33.1462 43.44 30.8888 41.7756 29.2244C40.1112 27.56 37.8538 26.625 35.5 26.625Z" fill="#713D11"/>
            </svg>
          </a>
          <a href="#" aria-label="Facebook">
            <svg width="80" height="80" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M61.6034 4.23157H6.62206C5.29982 4.23157 4.23157 5.29982 4.23157 6.62206V61.6034C4.23157 62.9257 5.29982 63.9939 6.62206 63.9939H61.6034C62.9257 63.9939 63.9939 62.9257 63.9939 61.6034V6.62206C63.9939 5.29982 62.9257 4.23157 61.6034 4.23157ZM59.2129 59.2129H45.4601V40.8509H53.2292L54.3946 31.8343H45.4601V26.0747C45.4601 23.4601 46.1847 21.6822 49.9274 21.6822H54.7009V13.6143C53.8717 13.5022 51.0404 13.2557 47.7386 13.2557C40.8509 13.2557 36.1372 17.4615 36.1372 25.1783V31.8268H28.3531V40.8435H36.1447V59.2129H9.01256V9.01256H59.2129V59.2129Z" fill="#713D11"/>
            </svg>
          </a>
          <a href="#" aria-label="LinkedIn" id='LinkedIn'>
            <svg width="80" height="80" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M64.125 10.125C65.9152 10.125 67.6321 10.8362 68.898 12.102C70.1638 13.3679 70.875 15.0848 70.875 16.875V64.125C70.875 65.9152 70.1638 67.6321 68.898 68.898C67.6321 70.1638 65.9152 70.875 64.125 70.875H16.875C15.0848 70.875 13.3679 70.1638 12.102 68.898C10.8362 67.6321 10.125 65.9152 10.125 64.125V16.875C10.125 15.0848 10.8362 13.3679 12.102 12.102C13.3679 10.8362 15.0848 10.125 16.875 10.125H64.125ZM62.4375 62.4375V44.55C62.4375 41.632 61.2783 38.8334 59.2149 36.7701C57.1515 34.7067 54.3529 33.5475 51.435 33.5475C47.6372 33.5475 45.3187 35.6063 44.2628 37.3434V33.675H33.9938V62.4375H44.625V46.875C44.625 44.8697 45.2629 42.959 46.5918 41.6301C47.9208 40.3011 49.8315 39.6625 51.8268 39.6625C53.8222 39.6625 55.7328 40.3011 57.0618 41.6301C58.3907 42.959 59.0288 44.8697 59.0288 46.875V62.4375H62.4375ZM24.975 62.4375H35.625V33.675H24.975V62.4375ZM30.3 28.35C31.8098 28.35 33.2561 27.7607 34.3312 26.6856C35.4063 25.6105 35.9957 24.1642 35.9957 22.6544C35.9957 21.1447 35.4063 19.6984 34.3312 18.6233C33.2561 17.5482 31.8098 16.9588 30.3 16.9588C28.7903 16.9588 27.344 17.5482 26.2689 18.6233C25.1938 19.6984 24.6045 21.1447 24.6045 22.6544C24.6045 24.1642 25.1938 25.6105 26.2689 26.6856C27.344 27.7607 28.7903 28.35 30.3 28.35Z" fill="#713D11"/>
            </svg>
          </a>
        </div>
      </div>
      <p>© 2020-2024 Logixcube - Cornu. Tous droits réservés.</p>
    </footer>
  );
}

export default Footer;
