import React from "react";
import "./Header.css";
import { FaBars, FaCocktail, FaPizzaSlice, FaIceCream } from "react-icons/fa";
import CurvedText from "./helper/CurvedText";
import { Images } from "../../assets";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Autoplay, Pagination } from "swiper/modules";
import { useNavigate } from "react-router-dom";



function Header() {
  const navigate = useNavigate();
  const MenuIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      className="w-8 h-8 text-white"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M4 6h16M4 12h16M4 18h16"
      />
    </svg>
  );

  return (
    <>
      <section id="home">
        <header className="header">
          <div className="header-top"></div>
          <div className="logo-container">
            <img
              className="logo"
              src={Images.MAIN_LOGO}
              alt="Cavallo Bianco Logo"
            />
          </div>

          <div className="header-content">
            <CurvedText />
            <h2>RESTAURANT PIZZERIA</h2>
            <p
              onClick={() => {
                window.open("https://maps.app.goo.gl/81Lc5FebSRuCt3Fn9", "_blank");
              }}
              style={{ cursor: "pointer" }}
            >
              Pl. Chauderon 24, 1003 Lausanne
            </p>
            <button
              className="reserve-button"
              onClick={() => {
                window.location.href = "tel:+41216250970";
              }}
            >
              RÉSERVER
            </button>
          </div>

          <div className="icon-section">
            <div className="swiper-container lg:hidden flex justify-center items-center">
              <Swiper
                spaceBetween={10}
                slidesPerView={1}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                centeredSlides={true}
                modules={[Autoplay]}
                breakpoints={{
                  480: { slidesPerView: 1 },

                  481: { slidesPerView: 3, centeredSlides: false },
                  640: { slidesPerView: 3, centeredSlides: false },
                  768: {
                    slidesPerView: 3,
                    centeredSlides: false,
                    spaceBetween: 20,
                  },
                  820: { slidesPerView: 1 },
                  1024: {
                    slidesPerView: 3,
                    centeredSlides: false,
                    spaceBetween: 50,
                  },
                }}
                className="py-8 w-full max-w-md mx-auto"
              >
                <SwiperSlide>
                  <div className="icon-with-description text-center"
                    onClick={() => document.getElementById("apropos").scrollIntoView({ behavior: "smooth" })}
                    style={{ cursor: "pointer" }}
                  >
                    <FaPizzaSlice size={40} className="mx-auto text-[#fff]" />
                    <p className="mt-2 text-sm">Authentiques, savoureuses, aux saveurs italiennes incomparables</p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="icon-with-description text-center"
                    onClick={() => document.getElementById("apropos").scrollIntoView({ behavior: "smooth" })}
                    style={{ cursor: "pointer" }}
                  >

                    <FaCocktail size={40} className="mx-auto text-[#fff]" />
                    <p className="mt-2 text-sm">Vins fins et cocktails pour sublimer chaque bouche</p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="icon-with-description text-center"
                    onClick={() => document.getElementById("apropos").scrollIntoView({ behavior: "smooth" })}
                    style={{ cursor: "pointer" }}
                  >
                    <FaIceCream size={40} className="mx-auto text-[#fff]" />
                    <p className="mt-2 text-sm">Douceur italienne : tiramisu, glaces crémeuses et plus</p>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>


            <div className="icon-static hidden lg:grid lg:grid-cols-3 lg:gap-8 lg:mt-8">
              <div
                className="icon-with-description text-center"
                onClick={() => document.getElementById("apropos").scrollIntoView({ behavior: "smooth" })}
                style={{ cursor: "pointer" }}
              >
                <FaPizzaSlice size={40} className="mx-auto text-[#fff]" />
                <p className="mt-2 text-sm">Authentiques, savoureuses, aux saveurs italiennes incomparables</p>
              </div>
              <div
                className="icon-with-description text-center"
                onClick={() => document.getElementById("apropos").scrollIntoView({ behavior: "smooth" })}
                style={{ cursor: "pointer" }}
              >
                <FaCocktail size={40} className="mx-auto text-[#fff]" />
                <p className="mt-2 text-sm">Vins fins et cocktails pour sublimer chaque bouche</p>
              </div>
              <div
                className="icon-with-description text-center"
                onClick={() => document.getElementById("apropos").scrollIntoView({ behavior: "smooth" })}
                style={{ cursor: "pointer" }}
              >
                <FaIceCream size={40} className="mx-auto text-[#fff]" />
                <p className="mt-2 text-sm">Douceur italienne : tiramisu, glaces crémeuses et plus</p>
              </div>
            </div>
          </div>
        </header>
      </section>
    </>
  );
}

export default Header;
