import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Sidebar/Sidebar";
import { FaTrash, FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import axiosInstance from "../api";

const ChefSuggestion = () => {
  const navigate = useNavigate();
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modal, isModal] = useState(false);
  const fetchDataValid = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      navigate("/login");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/auth/validateToken`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.isValid) {
        return;
      } else {
        navigate("login");
      }
    } catch (error) {
      console.error("Error during token validation:", error);
      navigate("login");
    }
  };

  useEffect(() => {
    fetchDataValid();
  }, []);
  const fetchSuggestions = async () => {
    try {
      const response = await axiosInstance.get(`/api/chef/get-chef`);
      setSuggestions(response.data.data);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    } finally {
      setLoading(false);
    }
  };

  const deleteSuggestion = async (id) => {
    try {
      await axiosInstance.delete(`/api/chef/delete/${id}`);
      setSuggestions(suggestions.filter((suggestion) => suggestion._id !== id));
    } catch (error) {
      console.error("Error deleting suggestion:", error);
    }
  };

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    category: "No",
    price: "",
    allergies: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? (checked ? "Yes" : "No") : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axiosInstance.post(`/api/chef/add-chef`, formData);
      toast.success("Chef suggestion added successfully!");
      fetchSuggestions();
      isModal(false);
      setFormData({
        name: "",
        description: "",
        category: "",
        allergies: "",
      });
    } catch (error) {
      toast.success("Something went wrong");
      console.error("Error adding chef suggestion:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSuggestions();
  }, []);

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [eidtformData, editsetFormData] = useState({
    name: "",
    category: "",
    description: "",
    price: "",
    isAvailable: true,
    allergies: "",
  });
  const openEditModal = (suggestion) => {
    setSelectedSuggestion(suggestion);
    editsetFormData({
      name: suggestion.name,
      description: suggestion.description,
      category: suggestion.category,
      price: suggestion.price,
      isAvailable: suggestion.isAvailable,
      allergies: suggestion.allergies,
    });
    setEditModalOpen(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    editsetFormData({
      ...eidtformData,
      [name]: value,
    });
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.put(
        `/api/chef/update/${selectedSuggestion._id}`,
        eidtformData
      );
      fetchSuggestions();
      setEditModalOpen(false);
      toast.success("Updated Successfully");
    } catch (error) {
      toast.error("Internal Server Error....");
      console.error("Error updating suggestion:", error);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  // Calculate the number of pages
  const totalPages = Math.ceil(suggestions.length / itemsPerPage);

  // Get the data for the current page
  const currentSuggestions = suggestions.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle page change
  const changePage = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-gray-200 rounded-lg dark:border-gray-700 mt-14">
          <div className="flex justify-end mb-4">
            <button
              className="px-4 py-2 text-black rounded-md bg-[#B1D4E0]-100 dark:bg-[#B1D4E0] "
              onClick={() => isModal(true)}
            >
              Ajout suggestion du chef
            </button>
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full text-sm text-left text-black-500 dark:text-black-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-300 dark:text-black-400">
                <tr>
                  <th className="px-6 py-3">Nom</th>
                  <th className="px-6 py-3">Description</th>
                  <th className="px-6 py-3">Prix</th>
                  <th className="px-6 py-3">Disponible</th>
                  <th className="px-6 py-3">Home Made</th>
                  <th className="px-6 py-3">Allergie</th>
                  <th className="px-6 py-3">Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentSuggestions && currentSuggestions.length > 0 ? (
                  currentSuggestions.map((suggestion) => (
                    <tr
                      key={suggestion._id}
                      className="border-b dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-200"
                    >
                      <td className="px-6 py-3">{suggestion.name}</td>
                      <td className="px-6 py-3">{suggestion.description}</td>

                      <td className="px-6 py-3">{suggestion.price}</td>
                      <td className="px-6 py-3">
                        {suggestion.isAvailable ? (
                          <span className="text-green-500">✔</span>
                        ) : (
                          <span className="text-red-500">✘</span>
                        )}
                      </td>
                      <td className="px-6 py-3">{suggestion.category}</td>
                      <td className="px-6 py-3">{suggestion.allergies}</td>
                      <td className="px-6 py-3">
                        <div className="flex space-x-2">
                          <FaTrash
                            className="h-6 w-6 text-red-500 cursor-pointer"
                            onClick={() => deleteSuggestion(suggestion._id)}
                          />
                          <FaEdit
                            className="h-6 w-6 text-blue-500 cursor-pointer"
                            onClick={() => openEditModal(suggestion)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="6"
                      className="text-center px-6 py-3 text-gray-500"
                    >
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {/* Pagination Controls */}
            <div className="flex justify-center items-center space-x-2 mt-4">
              <button
                className={`px-4 py-2 text-sm rounded ${
                  currentPage === 1
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-blue-500 text-white"
                }`}
                disabled={currentPage === 1}
                onClick={() => changePage(currentPage - 1)}
              >
                Avant
              </button>
              {Array.from({ length: totalPages }, (_, i) => (
                <button
                  key={i}
                  className={`px-4 py-2 text-sm rounded ${
                    currentPage === i + 1
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200"
                  }`}
                  onClick={() => changePage(i + 1)}
                >
                  {i + 1}
                </button>
              ))}
              <button
                className={`px-4 py-2 text-sm rounded ${
                  currentPage === totalPages
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-gray-900 text-white"
                }`}
                disabled={currentPage === totalPages}
                onClick={() => changePage(currentPage + 1)}
              >
                Après
              </button>
            </div>
          </div>

          {modal && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white rounded-lg shadow-lg w-96 p-6">
                <h2 className="text-xl font-bold mb-4">Ajout suggestion chef</h2>
                <form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Nom
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Description
                    </label>
                    <textarea
                      name="description"
                      value={formData.description}
                      onChange={handleChange}
                      rows="3"
                      className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Allergie
                    </label>
                    <textarea
                      name="allergies"
                      value={formData.allergies}
                      onChange={handleChange}
                      rows="3"
                      className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Home Made
                    </label>
                    <div className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        name="category"
                        checked={formData.category === "Yes"}
                        onChange={handleChange}
                        className="focus:outline-none focus:ring focus:ring-blue-300"
                      />
                      <span>{formData.category === "Yes" ? "Yes" : "No"}</span>
                    </div>
                    <p className="text-sm text-gray-500 mt-2">
                      C'est Home Made?
                    </p>
                  </div>

                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Prix
                    </label>
                    <input
                      type="text"
                      name="price"
                      value={formData.price}
                      onChange={handleChange}
                      className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                      required
                    />
                  </div>

                  <div className="flex justify-end">
                    <button
                      type="button"
                      onClick={() => isModal(false)}
                      className="px-4 py-2 bg-black text-white rounded-md mr-2"
                    >
                      Annuler
                    </button>
                    <button
                      type="submit"
                      className="px-4 py-2 text-black rounded-md bg-[#B1D4E0]-100 dark:bg-[#B1D4E0] "
                    >
                      Enregistrer
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}

          {editModalOpen && (
            <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white p-6 rounded-lg shadow-lg w-96">
                <h2 className="text-xl font-bold mb-4">Edit Chef Suggestion</h2>
                <form onSubmit={handleSubmitEdit}>
                  <div className="mb-4">
                    <label className="block text-gray-700">Nom</label>
                    <input
                      type="text"
                      name="name"
                      value={eidtformData.name}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Home Made
                    </label>
                    <div className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        name="category"
                        checked={formData.category === "Yes"}
                        onChange={handleChange}
                        className="focus:outline-none focus:ring focus:ring-blue-300"
                      />
                      <span>{formData.category === "Yes" ? "Yes" : "No"}</span>
                    </div>
                    <p className="text-sm text-gray-500 mt-2">
                      C'est Home Made?
                    </p>
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">Description</label>
                    <textarea
                      type="text"
                      name="description"
                      value={eidtformData.description}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">Allergie</label>
                    <input
                      type="text"
                      name="allergies"
                      value={eidtformData.allergies}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">Prix</label>
                    <input
                      type="text"
                      name="price"
                      value={eidtformData.price}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="inline-flex items-center text-gray-700">
                      <input
                        type="checkbox"
                        name="isAvailable"
                        checked={eidtformData.isAvailable}
                        onChange={() =>
                          editsetFormData({
                            ...eidtformData,
                            isAvailable: !eidtformData.isAvailable,
                          })
                        }
                        className="mr-2"
                      />
                      Disponible
                    </label>
                  </div>
                  <div className="flex justify-end">
                    <button
                      type="button"
                      onClick={() => setEditModalOpen(false)}
                      className="px-4 py-2 bg-black text-white rounded-md mr-2"
                    >
                      Annuler
                    </button>
                    <button
                      type="submit"
                      className="px-4 py-2 text-black rounded-md bg-[#B1D4E0]-100 dark:bg-[#B1D4E0] "
                    >
                      Enregistrer
                    </button>
                  </div>
                </form>
                <button
                  onClick={() => setEditModalOpen(false)}
                  className="absolute top-2 right-2 text-gray-600"
                >
                  X
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChefSuggestion;
