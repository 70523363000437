import React from "react";

function Carousel() {
  return (
    <>
      <header className="text-center py-10 mt-3 mb-5">
        <h1 className="text-4xl md:text-6xl lg:text-[80px] xl:text-[80px] tracking-widest font-[Bevan] text-[#713D11]">
          MENU
        </h1>
      </header>
    </>
  );
}

export default Carousel;
